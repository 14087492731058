import React from 'react';
import '../../assets/css/style.css';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import cookie from 'react-cookies';
import $ from "jquery";
import { trackEvent } from '../../lib/facebookPixel';

class footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_button: localStorage.getItem("active_button") ? localStorage.getItem("active_button") : 0,
            auto_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        };
    }

    handleHome = () => {
        this.handleAnimation()
        let success_action = "User clicked on home button on footer page"
        let image = 'home_button_footer_page.png'
        functions.logUserActivity(success_action, image);
        localStorage.removeItem("enter_relative_program")
        localStorage.removeItem("enter_relative_program_id")
        if (this.state.auto_id == "") {
            functions.pushToRoute(this.props, "", '')
            localStorage.removeItem("fb_post")
            localStorage.removeItem("session_entered_id")
        }
        else {
            localStorage.removeItem("session_entered_id")
            this.setState({ active_button: 0 })
            // 
            if(localStorage.getItem("detail_page") == "true"){
                localStorage.setItem("detail_page", 'false')
                localStorage.setItem("redirect_main", 'true')
                functions.pushToRoute(this.props, "family", '')
            }
            else{
                localStorage.setItem("detail_page", 'false')
                functions.pushToRoute(this.props, "main", '')
            }
            localStorage.setItem("active_button", "0")
        }
    }

    handleStar = () => {
        localStorage.removeItem("enter_relative_program")
        localStorage.removeItem("enter_relative_program_id")
        this.handleAnimation()
        let success_action = "User clicked on perk button on footer page"
        let image = 'perk_button_footer_page.png'
        functions.logUserActivity(success_action, image);
        this.setState({ active_button: 1 })
        localStorage.setItem("detail_page", 'false')
        functions.pushToRoute(this.props, "perks", '')
        localStorage.setItem("active_button", "1")
        localStorage.removeItem("fb_post")
        localStorage.removeItem("session_entered_id")
    }

    handleHeart = () => {
        localStorage.removeItem("enter_relative_program")
        localStorage.removeItem("enter_relative_program_id")
        this.handleAnimation()
        let success_action = "User clicked on family button on footer page"
        let image = 'family_button_footer_page.png'
        functions.logUserActivity(success_action, image);
        if (this.state.auto_id == "") {
            functions.pushToRoute(this.props, "home", '')
        }
        else {
            localStorage.setItem("redirect_main", 'false')
            this.setState({ active_button: 2 })
            localStorage.setItem("active_button", "2")
            localStorage.setItem("family_activeTab", 1)
            localStorage.setItem("detail_page", 'false')
            functions.pushToRoute(this.props, "family", '')
        }
    }

    handleInfo = () => {
        localStorage.removeItem("enter_relative_program")
        localStorage.removeItem("enter_relative_program_id")
        this.handleAnimation()
        let success_action = "User clicked on help button on footer page"
        let image = 'help_button_footer_page.png'
        functions.logUserActivity(success_action, image);
        if (this.state.auto_id == "") {
            functions.pushToRoute(this.props, "home", '')
        }
        else {
            this.setState({ active_button: 3 })
            localStorage.setItem("active_button", "3")
            localStorage.setItem("detail_page", 'true')
            localStorage.setItem('show_terms', false)
            functions.pushToRoute(this.props, "help", '')
        }
    }

    handleProfile = () => {
        localStorage.removeItem("enter_relative_program")
        localStorage.removeItem("enter_relative_program_id")
        let success_action = "User clicked on profile button on footer page"
        let image = 'profile_button_footer_page.png'
        functions.logUserActivity(success_action, image);
        trackEvent('User clicked on profile button', {event_action:"User clicked on profile button on Footer"})
        if (this.state.auto_id == "") {
            functions.pushToRoute(this.props, "home", '')
        }
        else {
            this.setState({ active_button: 4 })
            functions.pushToRoute(this.props, "profile", '')
            localStorage.setItem("active_button", "4")
        }
        this.handleAnimation()
    }

    componentDidMount = () => {
        let self = this;
        // window.addEventListener('storage', function (event) {
        //     if (event.key == 'active_button') {
        //         self.setState({ active_button: parseInt(event.newValue) })
        //     }
        // });
    }

    handleAnimation = () => {
        $('html, body').css('overflow', 'initial');
    }

    render() {

        return (
            < div className="webMenu" >
                <ul>
                   {/* <li className="mainIcon">
                      <a className={ this.state.active_button == 0 ? 'foterIconAct' : 'foterIconNonAct' } onClick={() => { this.handleHome() }}>
                        <img src={this.state.active_button == 0 ? require('../../assets/images/CategoryIconActive1.svg') : require('../../assets/images/CategoryIcon1_black.svg') } alt="" title="" />
                      </a>
                    </li>
                    <li className="perksIcon">
                      <a className={ this.state.active_button == 1 ? 'foterIconAct' : 'foterIconNonAct' } onClick={() => { this.handleStar() }}>
                        <img src={this.state.active_button == 1 ? require('../../assets/images/CategoryIconActive2.svg') : require('../../assets/images/CategoryIcon2_blak.svg') } alt="" title="" />
                      </a>
                    </li>
                    <li className="familyIcon">
                      <a className={ this.state.active_button == 2 ? 'foterIconAct' : 'foterIconNonAct' } onClick={() => { this.handleHeart() }}>
                        <img src={this.state.active_button == 2 ? require('../../assets/images/CategoryIconActive3.svg') : require('../../assets/images/CategoryIcon3_black.svg') } alt="" title="" />
                      </a>
                    </li> */}
                    {/* <li><a onClick={() => { this.handleInfo() }}><img src={this.state.active_button == 3 ? url.family_one_image_url + 'info_active.png' : url.family_one_image_url + 'Group78.png'} alt="" title="" /></a></li> */}
                    {/* <li className="profileIcon">
                      <a className={ this.state.active_button == 4 ? 'foterIconAct' : 'foterIconNonAct' } onClick={() => { this.handleProfile() }}>
                        <img src={this.state.active_button == 4 ? require('../../assets/images/CategoryIconActive4.svg') : require('../../assets/images/CategoryIcon4_blacj.svg') } alt="" title="" />
                      </a>
                    </li> */}

                   <li className="mainIcon">
                      <a className={ this.state.active_button == 0 ? 'cat1Act' : 'cat1NonAct' } onClick={() => { this.handleHome() }}>
                        <img src={this.state.active_button == 0 ? require('../../assets/images/cat1Active.svg') : require('../../assets/images/cat1.png') } alt="" title="" />
                      </a>
                    </li>
                    <li className="perksIcon">
                      <a className={ this.state.active_button == 1 ? 'cat2Act' : 'cat2NonAct' } onClick={() => { this.handleStar() }}>
                        <img src={this.state.active_button == 1 ? require('../../assets/images/cat2Active.svg') : require('../../assets/images/cat2.png') } alt="" title="" />
                      </a>
                    </li>
                    <li className="familyIcon">
                      <a className={ this.state.active_button == 2 ? 'cat3Act' : 'cat3NonAct' } onClick={() => { this.handleHeart() }}>
                        <img src={this.state.active_button == 2 ? require('../../assets/images/cat3Active.svg') : require('../../assets/images/cat3.png') } alt="" title=""
                        />
                      </a>
                    </li>
                    <li className="profileIcon">
                      <a className={ this.state.active_button == 4 ? 'cat4Act' : 'cat4NonAct' } onClick={() => { this.handleProfile() }}>
                        <img src={this.state.active_button == 4 ? require('../../assets/images/cat4Active.svg') : require('../../assets/images/cat4.png') } alt="" title=""
                        />
                      </a>
                    </li>

                </ul>
            </div >
        );
    }
}

export default footer;