import React, { Fragment } from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask'
import FormControl from '@material-ui/core/FormControl';
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import url from '../../assets/url';
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addYears } from 'date-fns';
import { Modal, ModalBody } from 'reactstrap';
import Enterphone from "./../innerpages/enterphone";
import setYear from 'date-fns/setYear';
import LottieLoader from '../LottieLoader';
import { trackEvent } from '../../lib/facebookPixel';


export default class Editprofile extends React.Component {
    constructor(props) {
        super(props);
        localStorage.setItem("resend_counter", 32)
        functions.handleBackOne(this, 'editprofile')
        this.state = {
            birthdate: localStorage.getItem('birthdate') ? localStorage.getItem('birthdate') : '',
            email: '',
            address: '',
            phone_number: localStorage.getItem('phone_number') ? localStorage.getItem('phone_number') : '',
            loader: false,
            alt_email: localStorage.getItem('alternative_email') ? localStorage.getItem('alternative_email') : '',
            show_alt_email: localStorage.getItem('alternative_email') ? true : false,
            show_error_message: '',
            verify_code: "",
            dynamic_content: "",
            resend_counter: localStorage.getItem("resend_counter") ? parseInt(localStorage.getItem("resend_counter")) : 32,
            given_name: "",
            family_name: "",
            given_name_complete: true,
            family_name_complete: true,
            isOpenPhoneNumber: false,
            initialViewDate: setYear(new Date(), new Date().getFullYear() - 17) // Set the initial view to 17 years ago
        }
    }

    handleEditAddress = () => {
        trackEvent('edit address button', {event_action: 'User clicked on Edit Address Button'})
        this.setState({ loader: true }, () => {
            // Check `localStorage` for `addressType` and act accordingly
            this.checkAddressType();
        });
 
    };

    checkAddressType = () => {
        const checkInterval = setInterval(() => {
            if (localStorage.getItem('addressType')) {
                // AddressType found, hide loader and execute the following actions
                clearInterval(checkInterval);
                this.setState({ loader: false }, () => {
                    let success_action = "User clicked on edit address button on editprofile page"
                    let image = 'edit_address_button_editprofile_page.png'
                    functions.logUserActivity(success_action, image);
                    localStorage.setItem("session_type", "edit_profile")
                    functions.pushToRoute(this, "address", "editprofile");
                });
            }
        }, 100); // Check every 100 milliseconds
    };

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }

        let success_action = "User landed on editprofile page"
        let image = 'editprofile_page_landing.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        let address_temp;
        let address;
        if (localStorage.getItem('address')) {
            address_temp = JSON.parse(localStorage.getItem('address'));
            let formatted_address = this.makeFormatAddress(address_temp)
            address = `${formatted_address.Apt ? formatted_address.Apt + ' - ' : ''}${formatted_address.addressline1}, ${formatted_address.addressline2}, ${formatted_address.city}, ${formatted_address.province}, ${formatted_address.postalcode}, ${formatted_address.country}`;

        }
        let given_name = localStorage.getItem("given_name") ? localStorage.getItem("given_name") : "";
        let family_name = localStorage.getItem("family_name") ? localStorage.getItem("family_name") : "";
        let birthdate = localStorage.getItem('birthdate') ? localStorage.getItem('birthdate') : '';
        let email = localStorage.getItem('email') ? localStorage.getItem('email') : '';
        let phone_number = localStorage.getItem('phone_number') ? localStorage.getItem('phone_number') : '';
        self.setState({ birthdate: birthdate, address: address, email: email, phone_number: phone_number, given_name: given_name, family_name: family_name });
        this.interval = setInterval(() => { this.handleCounter() }, 1000);
    }

    makeFormatAddress = (user_address) => {
        let format_address = {}
        if (Object.keys(user_address).length > 0) {

            format_address.Apt = user_address.Apt ? user_address.Apt : ""
            format_address.addressline1 = user_address.addressline1 ? user_address.addressline1.trim() : ""
            format_address.city = user_address.city ? user_address.city.trim() : ""
            format_address.province = user_address.province ? user_address.province.trim() : ""
            format_address.postalcode = user_address.postalcode ? user_address.postalcode.trim() : ""
            format_address.country = user_address.country ? user_address.country.trim() : ""
            format_address.addressline2 = ""
            let po_box_array = ['PO', 'P.O.', 'P.0', 'P0', 'Po Box']
            if (user_address.PO_Box && user_address.PO_Box != "") {
                let new_po_box = user_address.PO_Box.replace(/\D/g, '');
                user_address.PO_Box = new_po_box
            }

            // po_box_array.forEach(element => {
            //     console.log("user_address.PO_Box",user_address.PO_Box)
            //     if (user_address.PO_Box.search(element) != -1) {
            //         let temp_new_po_box = user_address.PO_Box.replace(element, '');
            //         new_po_box = temp_new_po_box.trim()
            //     }else{
            //         new_po_box = user_address.PO_Box.trim()
            //     }
            // });


            if (user_address.STN && user_address.STN != "") {
                if (format_address.addressline2 == "") {
                    if (user_address?.STN?.trim().substring(0, 3) == "STN") {
                        format_address.addressline2 = " " + user_address.STN.trim()
                    }
                    else {
                        format_address.addressline2 = user_address.STN.trim()
                    }
                } else {
                    if (user_address?.STN?.trim().substring(0, 3) == "STN") {
                        format_address.addressline2 = format_address.addressline2 + " " + user_address.STN.trim()
                    } else {
                        format_address.addressline2 = format_address.addressline2 + " " + user_address.STN.trim()
                    }
                }
            }
            if (user_address.toggle_element == "Sub") {

                let sub_value = user_address[user_address.sub_selected] ? user_address[user_address.sub_selected] : ""
                if (sub_value != "") {
                    if (user_address.sub_selected == "Apt" || user_address.sub_selected == "Unit" || user_address.sub_selected == "Suite" || user_address.sub_selected == "Condo") {
                        format_address.addressline1 = sub_value.trim() + "-" + format_address.addressline1.trim()
                    } else if (user_address.sub_selected == "Penthouse") {
                        format_address.addressline1 = "PH" + sub_value.trim() + "-" + format_address.addressline1.trim()
                    } else if (user_address.sub_selected == "Townhouse") {
                        format_address.addressline1 = sub_value.trim() + "-" + format_address.addressline1.trim()
                    }

                }
            }
            if (user_address.toggle_element == "Rural") {
                if (user_address.RR && user_address.RR != "") {
                    if (format_address.addressline2 == "") {
                        format_address.addressline2 = "RR " + user_address.RR.trim()
                    }
                    else {
                        format_address.addressline2 = "RR " + user_address.RR.trim() + " " + format_address.addressline2.trim()
                    }

                }

                if (user_address.Site && user_address.Site != "") {
                    if (format_address.addressline1 == "") {
                        format_address.addressline1 = "SITE " + user_address.Site.trim()
                    }
                    else {
                        format_address.addressline1 = "SITE " + user_address.Site.trim() + " " + format_address.addressline1.trim()
                    }
                }
                if (user_address.Comp && user_address.Comp != "") {
                    if (format_address.addressline1 == "") {
                        format_address.addressline1 = "COMP " + user_address.Comp.trim()
                    }
                    else {
                        format_address.addressline1 = "COMP " + user_address.Comp.trim() + " " + format_address.addressline1.trim()
                    }
                }
                if (user_address.general_delivery && user_address.general_delivery == "true") {
                    format_address.addressline1 = "GD " + format_address.addressline1.trim()
                }

                if (user_address.LCD && user_address.LCD == "true") {
                    if (format_address.addressline2 == "") {
                        format_address.addressline2 = "LCD MAIN"
                    }
                    else {
                        format_address.addressline2 = format_address.addressline2.trim() + " LCD MAIN"
                    }
                }
                if (user_address.PO_Box && user_address.PO_Box != "") {
                    format_address.addressline2 = "PO BOX " + user_address.PO_Box.trim() + " " + format_address.addressline2.trim()
                }
            } else {
                if (user_address.PO_Box && user_address.PO_Box != "") {
                    format_address.addressline2 = "PO BOX " + user_address.PO_Box.trim() + " " + format_address.addressline2.trim()
                }
            }
            if (format_address.addressline1 == "") {
                format_address.addressline1 = format_address.addressline2
                format_address.addressline2 = ""
            }
        }


        let self = this

        // let places_lat_long = []
        // Geocode.setApiKey("AIzaSyA10e_yHodj3O1MbN_bOBCPDnnP6GPCeN8")
        // let address_map = "L-10, Green Park Ext Rd, Block N, Green Park Extension, Green Park, New Delhi, Delhi 110016"
        // Geocode.fromAddress(address_map).then(
        //     response => {
        //         console.log("response", response)
        //         const { lat, lng } = response.results[0].geometry.location;
        //         places_lat_long.push({ lat: lat, lng: lng })
        //         self.setState({ places_lat_long: places_lat_long })
        //     }, error => {
        //         console.error(error);
        //     })

        return format_address
    }

    handleCounter = () => {
        if (this.state.resend_counter > 0) {
            this.setState({ resend_counter: this.state.resend_counter - 1 })
            localStorage.setItem("resend_counter", this.state.resend_counter)
        }
        if (this.state.resend_counter < 0) {
            clearInterval(this.interval);
        }
    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ')' + match[2] + '-' + match[3]
        }
        return null
    }

    // handleEditAddress = () => {
    //     let success_action = "User clicked on edit address button on editprofile page"
    //     let image = 'edit_address_button_editprofile_page.png'
    //     functions.logUserActivity(success_action, image);
    //     localStorage.setItem("session_type", "edit_profile")
    //     functions.pushToRoute(this, "address", "editprofile");
    // }

    handleDate = (date) => {
        let timestamp = new Date(date).getTime()
        // let minimum_date = new Date(this.state.minimum_date)
        // let maximum_date = new Date(this.state.maximum_date)
        // if(timestamp>= minimum_date.getTime() && timestamp <= maximum_date.getTime() || date ==''){
        this.setState({
            birthdate: date
        });
        this.UpdateData('birthdate', date)
        // }

    }


    UpdateData = (state_name, value) => {
        let user_action = "User got error on profile/editprofile file UpdateData function";

        let userdata = [];
        let dynamo_userdata = {};
        let update_flag = 0;
        switch (state_name) {
            case 'given_name':
                if (!this.state.given_name_complete || !this.state.given_name || this.state.given_name.length <= 1) {
                    let body = url.dynamic_content.error_message.given_name_length.en
                    functions.logUserError(user_action, body);
                    this.setState({ show_error_message: url.dynamic_content.error_message.given_name_length.en })
                } else {
                    localStorage.removeItem('given_name')
                    dynamo_userdata.given_name = this.state.given_name;
                    this.setState({ show_error_message: "" })
                    update_flag = 1;
                    localStorage.setItem('given_name', this.state.given_name)
                }
                break

            case 'family_name':
                if (!this.state.family_name_complete || !this.state.family_name || this.state.family_name.length <= 1) {
                    let body = url.dynamic_content.error_message.given_name_length.en
                    functions.logUserError(user_action, body);
                    this.setState({ show_error_message: url.dynamic_content.error_message.given_name_length.en })
                } else {
                    localStorage.removeItem('family_name')
                    dynamo_userdata.family_name = this.state.family_name;
                    this.setState({ show_error_message: "" })
                    update_flag = 1;
                    localStorage.setItem('family_name', this.state.family_name)
                }
                break

            case 'alt_email':
                localStorage.removeItem('alternative_email')
                if (!this.state.alt_email.match(/^([a-zA-Z0-9_.-]+@([\w-]+\.)+[\w-]{2,10})?$/)) {
                    let body = url.dynamic_content.error_message.invalid_email.en
                    functions.logUserError(user_action, body);
                    this.setState({ show_error_message: url.dynamic_content.error_message.invalid_email.en })
                } else {

                    dynamo_userdata.alternative_email = this.state.alt_email != "" ? this.state.alt_email : "delete";
                    dynamo_userdata.preferred_username = this.state.alt_email != "" ? this.state.alt_email : "delete";
                    let preferredUserNameValue = this.state.alt_email != "" ? this.state.alt_email : "delete";
                    userdata = [{ "Name": "preferred_username", "Value": preferredUserNameValue }];
                    this.setState({ show_error_message: "" })
                    update_flag = 1;
                    localStorage.setItem('alternative_email', this.state.alt_email)
                }
                break

            case 'phone_number':
                if (!this.state.phone_number.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)) {
                    let body = url.dynamic_content.error_message.invalid_phone.en
                    functions.logUserError(user_action, body);
                    this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en })
                } else {
                    dynamo_userdata.phone_number = this.state.phone_number;
                    this.setState({ show_error_message: "" })
                    update_flag = 1;
                    localStorage.setItem('phone_number', this.state.phone_number)
                }
                break

            case 'birthdate':
                if (value) {
                    dynamo_userdata.birthdate = value;
                    update_flag = 1;
                    localStorage.setItem('birthdate', value)
                }
            default:
        }

        if (this.state.show_error_message == "" && update_flag == 1) {
            console.log("userdata, dynamo_userdata", userdata, dynamo_userdata)
            functions.updateUserData(this, userdata, dynamo_userdata);
        }
    }

    handleContinue = (entered_phone_number) => {
        let user_action = "User got error on profile/editprofile file handleContinue function";
        let self = this;
        // localStorage.setItem("phone_number", entered_phone_number);
        let phone_number = this.formatPhoneNumber(entered_phone_number);
        if (phone_number == null || phone_number === "" || phone_number?.length < 12) {
            let body = url.dynamic_content.error_message.invalid_phone.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en })

        } else {
            let area_code = phone_number?.substring(1, 4).toString();
            let mode = "msg";
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = url.phone_code;
            let action = "send_code";
            self.setState({ loader: true })
            functions.sendVerifyCode(this, action, phone_number, sub_id, country_code, area_code, mode, '', function (response_json) {
                let response = JSON.parse(response_json);

                // if (response.error == 1 || response.data.result == "failed") {

                // }

                if (response.error == 1) {
                    self.setState({ loader: false })
                    let body = url.dynamic_content.error_message.wrong_happend.en
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: url.dynamic_content.error_message.wrong_happend.en })
                    localStorage.setItem("send_code", "false")
                }
                else if (response.data && response.data.result && response.data.result == "failed") {
                    self.setState({ loader: false })
                    let body = response.data.message
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: response.data.message })
                    localStorage.setItem("send_code", "false")
                }
                else if (response.data == "Successfull") {
                    self.setState({ loader: false })
                    self.setState({ send_code: true, resend_counter: 32 })
                    localStorage.setItem("send_code", "true")
                    localStorage.setItem("resend_counter", "32")
                }
                else if (response.data == "Exisitng Record") {
                    self.setState({ loader: false })
                    // self.props.onSubmit();
                }
            });
        }
    }

    handleResend = () => {
        let success_action = "User clicked on resend button on editprofile page"
        let image = 'resend_button_editprofile_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        localStorage.setItem("phone_number", this.state.phone_number);
        let phone_number = this.formatPhoneNumber(this.state.phone_number);
        self.setState({ resend_counter: 32 })

        localStorage.setItem("resend_counter", 32)
        if (phone_number == null || phone_number === "" || phone_number?.length < 12) {
            let user_action = "User got error on profile/editprofile file handleResend function";
            let body = url.dynamic_content.error_message.invalid_phone.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en })
        } else {
            let area_code = phone_number?.substring(1, 4).toString();
            let mode = "msg";
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = url.phone_code;
            let action = "send_code";
            self.setState({ loader: true })
            functions.sendVerifyCode(this, action, phone_number, sub_id, country_code, area_code, mode, '', function (response_json) {
                let response = JSON.parse(response_json);
                if (response.error == 1) {
                    let success_action = "User got error on sendVerifyCode function on editprofile page"
                    let image = 'error_response_for_sendverifycode_editprofile_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    localStorage.setItem("send_code", "false")
                }
                else if (response.data == "Successfull") {
                    let success_action = "User got success on sendVerifyCode function on editprofile page"
                    let image = 'success_response_for_sendverifycode_editprofile_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    self.setState({ send_code: true, resend_counter: 32 })
                    localStorage.setItem("send_code", "true")
                    localStorage.setItem("resend_counter", "32")
                }
                else if (response.data == "Exisitng Record") {
                    let success_action = "User got Exisitng Record on sendVerifyCode function on editprofile page"
                    let image = 'existing_record_for_sendverifycode_editprofile_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    // self.props.onSubmit();s
                }
            });
        }
    }

    handleNext = (verify_code) => {
        let self = this;
        let success_action = "User clicked on next button on editprofile page"
        let image = 'next_button_editprofile_page.png'
        functions.logUserActivity(success_action, image);
        self.setState({ loader: false })
        if (verify_code === "" || !verify_code) {
            let user_action = "User got error on profile/editprofile file handleNext function";
            let body = url.dynamic_content.error_message.verify_code_missing.en
            functions.logUserError(user_action, body);
            this.setState({
                show_error_message: url.dynamic_content.error_message.verify_code_missing.en
            });
        } else if (!verify_code.match(/^\d{6}$/)) {
            let user_action = "User got error on profile/editprofile file handleNext function";
            let body = url.dynamic_content.error_message.invalid_verify_code.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_verify_code.en });
        } else {
            let phone_number = this.formatPhoneNumber(this.state.phone_number);
            let area_code = phone_number?.substring(1, 4).toString();
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = url.phone_code;
            if (!phone_number) {
                this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en });
                return;
            } else if (!verify_code) {
                this.setState({ show_error_message: url.dynamic_content.error_message.invalid_verify_code.en });
                return;
            }
            let action = "verify_code";
            functions.checkVerifyCode(this, action, phone_number, sub_id, country_code, area_code, verify_code, function (response_json) {
                let response = JSON.parse(response_json);
                // if (response.error == 1 || response.data.result == "Not Match" || response.data.result == "Match") {
                // }
                if (response.error == 1) {
                    let user_action = "User got error on profile/editprofile file handleNext function";
                    let body = ""
                    functions.logUserError(user_action, body);
                    localStorage.setItem("send_code", "false")
                }
                else if (response.data && response.data.result && response.data.result == "Not Match") {
                    let user_action = "User got error on profile/editprofile file handleNext function";
                    let body = url.dynamic_content.error_message.incorrect_code.en
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: url.dynamic_content.error_message.incorrect_code.en })
                }
                else if (response.data && response.data.result && response.data.result == "Match") {
                    let success_action = "User got success on sendVerifyCode function on editprofile page"
                    let image = 'success_response_for_sendverifycode_editprofile_page.png'
                    functions.logUserActivity(success_action, image);
                    localStorage.setItem("phone_verified", "true");
                    self.setState({ loader: false, send_code: false })
                    this.UpdateData('phone_number', '')
                }
            });
        }
    }

    handleCall = () => {
        let success_action = "User clicked on call button on editprofile page"
        let image = 'call_button_editprofile_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        localStorage.setItem("phone_number", this.state.phone_number);
        let phone_number = this.formatPhoneNumber(this.state.phone_number);
        if (phone_number == null || phone_number === "" || phone_number?.length < 12) {
            let user_action = "User got error on profile/editprofile file handleCall function";
            let body = url.dynamic_content.error_message.invalid_phone.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en })
        } else {
            let area_code = phone_number?.substring(1, 4).toString();
            let mode = "call";
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = url.phone_code;
            let action = "send_code";
            self.setState({ loader: true })
            functions.sendVerifyCode(this, action, phone_number, sub_id, country_code, area_code, mode, '', function (response_json) {
                let response = JSON.parse(response_json);
                if (response.error == 1) {
                    let success_action = "User got error on sendVerifyCode function on editprofile page"
                    let image = 'error_response_for_sendverifycode_editprofile_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    localStorage.setItem("send_code", "false")
                }
                else if (response.data == "Successfull") {
                    let success_action = "User got success on sendVerifyCode function on editprofile page"
                    let image = 'success_response_for_sendverifycode_editprofile_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    self.setState({ send_code: true, resend_counter: 32 })
                    localStorage.setItem("send_code", "true")
                    localStorage.setItem("resend_counter", "32")
                }
                else if (response.data == "Exisitng Record") {
                    let success_action = "User got Exisitng Record on sendVerifyCode on editprofile page"
                    let image = 'existing_record_for_sendverifycode_editprofile_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    // self.props.onSubmit();
                }
            });
        }
    }

    handlePhone = (e) => {
        let phone_number = '';
        phone_number = e.target.value
        this.setState({ phone_number: e.target.value, show_error_message: "" })
        let check_phone = phone_number.replace(/_/g, '')
        if (check_phone?.length === 13) {
            this.handleContinue(phone_number)
        }
    }

    handleVerify = (e) => {
        let success_action = "User clicked on verify code on editprofile page"
        let image = 'verify_code_button_editprofile_page.png'
        functions.logUserActivity(success_action, image);
        let verify_code = '';
        verify_code = e.target.value
        if (e.target.value.match(/^[0-9]{0,6}$/)) {
            this.setState({ verify_code: e.target.value, show_error_message: '' })
        }
        if (verify_code?.length == 6) {
            this.handleNext(verify_code)
        }
    }

    ValidateEmail = (alt_email) => {
        let user_action = "User got error on profile/editprofile file ValidateEmail function";
        let self = this
        let finaljson = {
            email: alt_email
        }
        functions.ValidateEmailForBounce(this, finaljson, function (response_json1) {
            let response1 = JSON.parse(response_json1);

            if (response1.data.success == 0) {
                self.setState({ loader: false })
                let body = url.dynamic_content.error_message.invalid_email.en
                functions.logUserError(user_action, body);
                self.setState({ show_error_message: url.dynamic_content.error_message.invalid_email.en })
            } else {
                self.UpdateData('alt_email', '')
            }
        })
    }

    handleFirstname = (firstName) => {
        let user_action = "User got error on profile/editprofile file UpdateData function";
        let body = url.dynamic_content.error_message.given_name_length.en

        if (!firstName.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.given_name_length.en })
        }
        else {
            this.setState({
                given_name: firstName.trim(),
                show_error_message: ''
            });
            if (firstName.trim()?.length > 1) {
                this.setState({
                    given_name_complete: true
                });
                this.UpdateData('given_name', firstName.trim())
            } else {
                this.setState({
                    given_name_complete: false
                });
                functions.logUserError(user_action, body);
                this.setState({ show_error_message: url.dynamic_content.error_message.given_name_length.en })
            }
        }
    }

    handleLastname = (lastName) => {
        let user_action = "User got error on profile/editprofile file UpdateData function";
        let body = url.dynamic_content.error_message.given_name_length.en

        if (!lastName.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.given_name_length.en })
        }
        else {
            this.setState({
                family_name: lastName.trim(),
                show_error_message: ''
            });
            if (lastName.trim()?.length > 1) {
                this.setState({
                    family_name_complete: true
                });
                this.UpdateData('family_name', lastName.trim())
            } else {
                this.setState({
                    family_name_complete: false
                });
                functions.logUserError(user_action, body);
                this.setState({ show_error_message: url.dynamic_content.error_message.given_name_length.en })
            }
        }
    }

    componentDidMount = () => {
        // Step 1: Retrieve the value from localStorage
        const phoneTransfer = localStorage.getItem('phone_transfer');
        const emailTransfer = localStorage.getItem('email_transfer');
        const email = localStorage.getItem('email');



        // Step 2: Check if the value exists
        if (phoneTransfer && emailTransfer && emailTransfer === email) {
            this.setState({ isOpenPhoneNumber: true });
        }
    }


    togglePhoneNumber = () => {
        this.setState({ isOpenPhoneNumber: !this.state.isOpenPhoneNumber });
    }

    onSubmit = (phone_number) => {

        this.setState({ isOpenPhoneNumber: false, phone_number: phone_number });
    }



    render() {
        const { dynamic_content } = this.state;
        const maxDate = addYears(new Date(), -17);
        return (
            <div className="container">
                {dynamic_content != "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                            <div className="common_main gainextra_formFull">
                                <div className="sign_backlink sign_backlinkNw" onClick={() => { functions.pushToRoute(this, "profile", 'editprofile') }}>
                                    <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                </div>
                                {/* <div className="clearlink"><img src={require('../../assets/images/Group5.png'} alt="" title="" /></div> */}
                                <div className="profileTp">
                                    <h1>Profile</h1>
                                    <h2>{dynamic_content.static_text.editprofile ? dynamic_content.static_text.editprofile.edit_profile.en : "Edit profile"}</h2>
                                </div>

                                <div className="gainextra_form 6767">

                                    <FormControl>
                                        <div>
                                            {/* <TextField
                                            autoComplete='off'
                                            autoFocus={true}
                                            label="First Name"
                                            type="text"
                                            margin="normal"
                                            inputRef={(input) => { this.given_name = input; }}
                                            className="signup_first"
                                            value={this.state.given_name ? this.state.given_name : ""}
                                            onChange={(e) => { this.setState({ given_name: e.target.value }) }}
                                            onBlur={() => this.handleFirstname(this.state.given_name)}
                                        /> */}

                                            <div className='editUsrInfo'>
                                                <label>First Name</label>
                                                <input
                                                    autoComplete='off'
                                                    autoFocus={true}
                                                    //label="First Name"
                                                    //placeholder="First Name"
                                                    type="text"
                                                    margin="normal"
                                                    ref={(input) => { this.given_name = input; }}
                                                    className="signup_first"
                                                    value={this.state.given_name ? this.state.given_name : ""}
                                                    onChange={(e) => { this.setState({ given_name: e.target.value }) }}
                                                    onBlur={() => this.handleFirstname(this.state.given_name)}
                                                />
                                            </div>

                                            <div className='editUsrInfo'>
                                                <label>Last Name</label>
                                                <input
                                                    autoComplete='off'
                                                    //label="Last Name"
                                                    //placeholder="First Name"
                                                    type="text"
                                                    margin="normal"
                                                    ref={(input) => { this.family_name = input; }}
                                                    autoFocus={false}
                                                    className="signup_last"
                                                    value={this.state.family_name ? this.state.family_name : ""}
                                                    onChange={(e) => { this.setState({ family_name: e.target.value }) }}
                                                    onBlur={() => this.handleLastname(this.state.family_name)}
                                                />
                                            </div>

                                            <div className='editUsrInfo editDobusr'>
                                                <label>Enter date of birth</label>

                                                {/* <input
                                            autoComplete='off'
                                            //label="Enter date of birth"
                                            //placeholder="Enter date of birth"
                                            type="date"
                                            margin="normal"
                                            value={this.state.birthdate}
                                            onChange={(e) => { this.handleDate(e.target.value) }}
                                            className="rm_transform"
                                          /> */}

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Fragment>
                                                        <div className="addChildDateIcon">
                                                            <DatePicker
                                                                openTo="year"
                                                                format="dd/MM/yyyy"
                                                                id="select_date"
                                                                maxDate={maxDate}
                                                                //maxDate={maxDate}
                                                                //maxDate ={addYears(new Date(), -17)}
                                                                views={["year", "month", "date"]}
                                                                //placeholder="Enter date of birth"
                                                                value={this.state.birthdate ? this.state.birthdate : null}
                                                                onChange={this.handleDate}
                                                                className="rm_transform"
                                                                margin="normal"
                                                                initialFocusedDate={this.state.initialViewDate}
                                                            />
                                                        </div>
                                                    </Fragment>
                                                </MuiPickersUtilsProvider>

                                            </div>

                                            <div className='editUsrInfo'>
                                                <label>Email</label>
                                                <input
                                                    autoComplete='off'
                                                    //label="Email"
                                                    //placeholder='Email'
                                                    type="text"
                                                    margin="normal"
                                                    value={this.state.email}
                                                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                                                    disabled={true}
                                                />
                                            </div>

                                            {this.state.show_alt_email &&
                                                <div className='editUsrInfo'>
                                                    <label>Alternative Email</label>
                                                    <input
                                                        autoComplete='off'
                                                        //label="Alternative Email"
                                                        //placeholder='Alternative Email'
                                                        type="text"
                                                        margin="normal"
                                                        value={this.state.alt_email}
                                                        onChange={(e) => { this.setState({ alt_email: e.target.value }) }}
                                                        onBlur={() => this.ValidateEmail(this.state.alt_email)}
                                                    />
                                                </div>
                                            }

                                            {this.state.show_alt_email == false &&
                                                <div className="add_emails" onClick={() => { this.setState({ "show_alt_email": true }) }}><h3>{dynamic_content.static_text.editprofile ? dynamic_content.static_text.editprofile.add_email.en : "Add Email"}</h3></div>
                                            }


                                            {this.state.phone_number && !this.state.send_code &&
                                                <div className="editUsrInfo editUsrInfoMask">
                                                    <label>{dynamic_content.static_text.editprofile ? dynamic_content.static_text.editprofile.phone.en : "Phone"}</label>

                                                    <MaskedInput
                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                        // placeholder="(999)999-9999"
                                                        className=""
                                                        value={this.state.phone_number}
                                                        onChange={(e) => { this.handlePhone(e) }}
                                                        autoFocus={false}
                                                    // onKeyPress={event => {
                                                    //     if (event.key === "Enter") {
                                                    //         this.handleContinue()
                                                    //     }
                                                    // }}
                                                    />

                                                    {this.state.phone_number &&
                                                        // <div className="editAddresUsr">
                                                        //     <h3 onClick={() => { this.togglePhoneNumber() }}>
                                                        //         {"Add Phone number"}
                                                        //     </h3>
                                                        // </div>
                                                        <div className="addMaskPhone" onClick={() => { this.togglePhoneNumber() }}><h3>Modify</h3></div>
                                                    }
                                                </div>
                                            }
                                            {!this.state.phone_number &&
                                                // <div className="editAddresUsr">
                                                //     <h3 onClick={() => { this.togglePhoneNumber() }}>
                                                //         {"Add Phone number"}
                                                //     </h3>
                                                // </div>
                                                <div className="add_emails" onClick={() => { this.togglePhoneNumber() }}><h3>Add Phone number</h3></div>
                                            }
                                            {/* {this.state.send_code &&
                                                <TextField
                                                    autoFocus={true}
                                                    autoComplete='off'
                                                    label="Verification code"
                                                    type="text"
                                                    margin="normal"
                                                    inputProps={{ maxLength: 6 }}
                                                    value={this.state.verify_code}
                                                    onChange={(e) => { this.handleVerify(e) }}
                                                />
                                            }
                                            {this.state.send_code &&
                                                <div className="contest_call">
                                                    {this.state.resend_counter < 1 &&
                                                        <div className="resend_active" onClick={() => { this.handleResend() }}><p>{dynamic_content.static_text.editprofile ? dynamic_content.static_text.editprofile.resend.en : "Resend"}</p></div>
                                                    }
                                                    {this.state.resend_counter > 0 &&
                                                        <div className="resend" > <p><span>Resend (00:{this.state.resend_counter > 9 ? this.state.resend_counter : "0" + this.state.resend_counter})</span></p></div>
                                                    }
                                                    <div onClick={() => { this.handleCall() }}><p style={{ textAlign: "right" }}>{dynamic_content.static_text.editprofile ? dynamic_content.static_text.editprofile.call.en : "Call"}</p></div>
                                                </div>
                                            } */}


                                            <div className='editUsrInfo'>
                                                <label>Address</label>
                                                <textarea
                                                    autoComplete='off'
                                                    //label="Address"
                                                    //placeholder='Address'
                                                    type="text"
                                                    margin="normal"
                                                    multiline
                                                    rowsMax="6"
                                                    value={this.state.address}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className="editAddresUsr">
                <h3 onClick={this.handleEditAddress}>
                    <img src={require('../../assets/images/editAddress.svg')} alt="" title="" />
                    {dynamic_content.static_text.editprofile 
                        ? dynamic_content.static_text.editprofile.edit_address.en 
                        : "Edit Address"}
                </h3>
                {this.state.loader && (
                    <center>
                        <LottieLoader />
                    </center>
                )}
            </div>


                                        </div>
                                        {this.state.show_error_message && this.state.show_error_message != '' &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }
                                        <div className='profileApply'>
                                            <Button size="medium" color="primary" onClick={() => { functions.pushToRoute(this, "profile", 'editprofile') }}>
                                                Update Profile
                                            </Button>
                                        </div>
                                    </FormControl>
                                    <Modal isOpen={this.state.isOpenPhoneNumber} toggle={this.togglePhoneNumber} className={this.state.className + " custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                                        <ModalBody className="revwPending">
                                            <a onClick={() => this.togglePhoneNumber()}>
                                                <img src={require('../../assets/images/closeChild.svg')} alt="" title="" className="closeRvew" /></a>
                                            <div className="revwPendingTxt">
                                                <Enterphone props={this.props} Back={this.togglePhoneNumber} onSubmit={this.onSubmit} />
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}