import { InputAdornment, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import session from '../../assets/functions/session';
import LottieLoader from '../LottieLoader';
import cookie from 'react-cookies';
import axios from 'axios';
import url from '../../assets/url';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import { trackEvent } from '../../lib/facebookPixel';

const styles = theme => ({
    eye: {
        cursor: 'pointer',
    },
});

class PasswordInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordIsMasked: true,
        };
    }

    togglePasswordMask = () => {
        this.setState(prevState => ({
            passwordIsMasked: !prevState.passwordIsMasked,
        }));
    };

    render() {
        const { classes } = this.props;
        const { passwordIsMasked } = this.state;

        return (
            <TextField
                type={passwordIsMasked ? 'password' : 'text'}
                {...this.props}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {passwordIsMasked ? (
                                <img src={require('../../assets/images/eyeShow.svg')} alt="" title="" className={classes.eye} onClick={this.togglePasswordMask} />
                            ) : (
                                <img src={require('../../assets/images/eyeHide.svg')} alt="" title="" className={classes.eye} onClick={this.togglePasswordMask} />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        );
    }
}

PasswordInput.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.func.isRequired,
};

PasswordInput = withStyles(styles)(PasswordInput);

/* --------------------------------------------------------- */

class Enterpassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            showPassword: false,
            show_error_message: "",
            mail_sent: false,
            email: localStorage.getItem("email"),
            loader: false,
            dynamic_content: ""
        };
    }

    componentDidMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        dynamic_content.static_text.enterpassword.email_message.en = dynamic_content.static_text.enterpassword.email_message.en.replace("%EMAIL%", this.state.email)
        let action = "User landed on enterpassword page"
        let image = 'enterpassword_page_landing.png'
        functions.logUserActivity(action, image);
        let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null"
        if (session_id != "null") {
            session.updatePageSession(session_id, "enterpassword")
        }
    }

    onChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value, show_error_message: "" });
    };

    handleContinue = () => {
        let user_action = "User got error on login/enterpassword file handleContinue function";

        let action = "User clicked on Continue on enterpassword page"
        let image = 'continue_button_enterpassword_page.png'
        functions.logUserActivity(action, image);
        if (this.state.password == "") {
            let body = url.dynamic_content.error_message.password_length.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.password_length.en });
        }
        else if (this.state.password.length <= 7) {
            let body = url.dynamic_content.error_message.password_length.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.password_length.en });
        }
        else if (this.state.password.length >= 8) {
            //Function for login with username and password
            let self = this;
            let email = localStorage.getItem("email")
            self.setState({ loader: true })
            functions.passwordLogin(this, email, this.state.password, function (response_json) {
                let response = JSON.parse(response_json);
                if (response.catch_error) {
                    self.setState({ loader: false })
                    let body = response.message
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: response.message });
                }
                else if (!response.catch_error) {
                    if (response.data.error === 1) {
                        self.setState({ loader: false })
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        self.setState({ show_error_message: response.data.message })
                    } else if (response.data.statusCode === 400) {
                        self.setState({ loader: false })
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        self.setState({ show_error_message: response.data.message })
                    }
                    if (response.data.success === 1) {
                        let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
                        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
                        let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
                        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";
                        let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
                        let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
                        let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
                        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
                        localStorage.clear();
                        localStorage.setItem("user_signup_process", "false")
                        localStorage.setItem("session_entered_id", session_entered_id)
                        localStorage.setItem("perks_or_main", perks_or_main)
                        localStorage.setItem("previous_state", previous_state)
                        localStorage.setItem("current_state", current_state)
                        localStorage.setItem("api_auth", api_auth)
                        localStorage.setItem("program", JSON.stringify(program))
                        localStorage.setItem("program_list", JSON.stringify(program_list))
                        localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
                        let data = response.data.profile_data;
                        let cognito_data = data.cognito ? data.cognito.UserAttributes : []
                        let dynamo_data = data.dynamo.Items ? data.dynamo.Items[0] : {}
                        let relation_data = data.relation_data ? data.relation_data : {}
                        let program_data = data.program_data ? data.program_data : []
                        let winner_data = data.winner_data ? data.winner_data : [];
                        session.checkSession(dynamo_data.auto_id, "login", 'false');
                        if (Array.isArray(winner_data) && winner_data.length > 0) {
                            let final_winner_data = [];
                            winner_data.forEach(element => {
                                if (element.claim_status == "pending") {
                                    let date1 = new Date();
                                    let date2 = new Date(element.claim_expiry_date);
                                    var Difference_In_Time = date2.getTime() - date1.getTime();
                                    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                                    if (Difference_In_Days < 8) {
                                        final_winner_data.push(element);
                                    }
                                }
                            });
                            localStorage.setItem("winner_data", JSON.stringify(final_winner_data))
                        }
                        let winner_id = cookie.load("winner_id") ? cookie.load("winner_id") : ""
                        if (winner_id != "" && typeof winner_id != undefined && winner_id != dynamo_data.auto_id) {
                            cookie.remove("winner_id", { path: "/" })
                            cookie.remove("winner_program_id", { path: "/" })
                        }
                        let element = document.getElementById("login_complete")
                        console.log("element", element)
                        element.click();
                        trackEvent('Login Completed New App', {event_action: 'User successfully completed login process'})
                        let session_type = 'login'
                        functions.storeCookie(cognito_data, dynamo_data, relation_data, program_data, 'true', self, 'password', session_type);
                        // functions.getLandingPage(self)
                        self.setState({ loader: false })
                    }
                }
            })
        }



    }

    handleForgotPassword = () => {
        let action = "User clicked on forgot password on enterpassword page"
        let image = 'forgot_password_button_enterpassword_page.png'
        functions.logUserActivity(action, image);
        this.handleSend()
    }

    handleSend = () => {
        let self = this;
        let email = localStorage.getItem("email")
        this.setState({ loader: true })
        let finalJson = {
            "action": "forget_password",
            "email": email
        }
        axios
            .request({
                method: "POST",
                url: url.forget_email,
                data: JSON.stringify(finalJson),
                headers: {
                    "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
                }
            })
            .then(function (response) {
                self.setState({ loader: false })
                if (response.data.failed && response.data.failed == 1) {
                    functions.handleErrorPopup()
                }
                if (response.data.success == 1) {
                    let success_action = "User got success response on handleSend function on enterpassword page"
                    functions.logUserActivity(success_action, null);
                    self.setState({ mail_sent: true })
                    // functions.pushToRoute(self, 'restorepasswordsent', 'restorepassword');
                }
                if (response.data.result == "failed") {
                    let user_action = "User got error on login/enterpassword file handleSend function";
                    let body = response.data.message
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: response.data.message })
                }
            })
            .catch(function (error) {
                let user_action = "User got error on login/enterpassword file handleSend function";
                let body = error.message
                functions.logUserError(user_action, body);
            });
    }

    render() {
        const { password } = this.state;
        const { dynamic_content } = this.state
        return (
            <div id="login_complete" className="container">
                {this.state.dynamic_content != "" &&
                    <div className="row">
                      <div className="common_mainAddOn">
                       <div className='usPassSetBg'>  
                        <div className="common_main">
                            <div className="sign_backlink sign_backlinkNw"
                              onClick={() => {
                                let action = "User clicked on back button on enterpassword page"
                                let image = 'back_button_enterpassword_page.png'
                                functions.logUserActivity(action, image);
                                this.props.history.push("/login")
                            }} 
                            >
                                <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                            </div>

                            <div className="log_up">
                                <h1>{dynamic_content.static_text.enterpassword.log_in.en}</h1>
                            </div>
                            {this.state.loader &&
                                <center>
                                    <LottieLoader className="loader_class" color="secondary" />
                                </center>
                            }
                            {!this.state.loader &&
                                <div className="logup_form">
                                    <div className="show_password" >
                                        <PasswordInput
                                            hiddenlabel="Password"
                                            placeholder="Password"
                                            name="password"
                                            value={password}
                                            onChange={this.onChange}
                                            className="type_password"
                                            onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    this.handleContinue()
                                                }
                                            }}
                                        />
                                    </div>
                                    {this.state.show_error_message && this.state.show_error_message != "" &&
                                        <p className="error_message">
                                            {/* {this.state.show_error_message} */}
                                            {/* Wrong password  */}
                                            Wrong password. Try again or click Forgot password to reset it
                                        </p>
                                    }
                                    <div className="logup_submit 1213">
                                        <Button size="medium" color="primary" onClick={() => { this.handleContinue() }}>
                                            {dynamic_content.static_text.enterpassword.log_in.en}
                                        </Button>
                                    </div>
                                </div>
                            }
                            {!this.state.loader && this.state.mail_sent &&
                                <div className="restoreemail_content">
                                    <h1>{dynamic_content.static_text.enterpassword.sent.en}!</h1>
                                    <p>
                                        {ReactHtmlParser(dynamic_content.static_text.enterpassword.email_message.en)}
                                    </p>
                                </div>
                            }
                            {!this.state.loader && !this.state.mail_sent &&
                                <div className="forget_email">
                                    <p onClick={() => { this.handleForgotPassword() }}>{dynamic_content.static_text.enterpassword.forgot_password.en}</p>
                                </div>
                            }
                        </div>
                       </div>
                      </div>  
                    </div>
                }
            </div>
        );
    }
}

export default Enterpassword;
