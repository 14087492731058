export default {
    s3: {
        REGION: "us-east-1",
        BUCKET: "app2.family.one"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://familyonetest/"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_0leMn10UW",
        APP_CLIENT_ID: "42j44ianhbbdc4h5gtl9cffjvk",
        IDENTITY_POOL_ID: "us-east-1:00319031-a872-4cac-b08d-eec7f540ba56"
    },
    social: {
        FB: "231822734692164",
        FB_PIXEL : '518052031700225'
    }
};